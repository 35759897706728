/* Left and Right Blocks */
.left-right-block {
  background-color: #6076ab;
  position: relative;
  text-align: left;
  padding: 3px !important;
  margin-bottom: 8px;
  color: #ffffff;
}
.left-right-block .field-column {
  width: 38.76%;
}
.left-right-block .text-column {
  width: 61.24%;
}
.left-right-block .Arrow-class {
  position: absolute;
  top: 0;
  width: 30px;
  z-index: 10;
}
.Vertical-droppable-field {
  background-color: #ffffff;
  height: 80px;
  text-align: center;
}

.investmentLine {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.capitalizeLetter {
  text-transform: capitalize;
}
.lowwerCaseLetter {
  text-transform: lowercase;
}

.checkListValue p {
  margin-bottom: 0px;
}