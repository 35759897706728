.Production-column {
    padding: 0px 5px 5px 0px;
    width: 40%;
    margin: 5px;
}
.Production-column:last-child {
    padding: 0px 0px 5px 0px !important;
}

.Production-block-container {
    background-color: #DCDDDE;
    border: 1px solid #FFFFFF;
    text-align: left;
    padding: 5px !important;
}

.Production-container .Block-title-text-size {
	text-transform: uppercase;
}

.Production-title-text-size {
    font-size: 10pt;
}

.Production-title-text-size.Same-line {
    margin-right: 2px !important;
}

.production-block-title-box {
    background-color: #FFFFFF;
    height: 30px;
    width: 90%;
    display: table-cell;
    vertical-align: middle;
}
.production-block-title-box-text {
    text-align: center;
    font-weight: bold;
    word-break: break-word;
    font-size: 5pt;
}

.Production-block-container .row.custom-height {
    height: 25px;
}

.Production-sub-title-text-size {
    font-size: 5pt;
    margin-right: 2px !important;
    float: left;
}

.custom-row-line {
    position: relative;
    height: 20px;
}
.custom-row-line::before {
    content: "";
    background-color: #FFFFFF;
    opacity: 0.4;
    border: 1px solid #FFFFFF;
    top: 2px;
    bottom: 2px;
    left: 0;
    right: 0;
    position: absolute;
    z-index: 2;
}

.speed-text {
    font-size: 5pt;
}
.speed-text.center {
    text-align: center;
}
.speed-text .bold-text {
    font-weight: bold;
}

.Production-block-container .standard {
    background-color: #23923C;
    /*margin-right: 5px;*/
    width: 90%;
}
.Production-block-container .compact {
    background-color: #FBD901;
    /*margin-right: 5px;*/
    width: 90%;
}
.Production-block-container .plus {
    background-color: #2AACD5;
    /*margin-right: 5px;*/
    width: 90%;
}
.Production-block-container .luxus {
    background-color: #901027;
    width: 90%;
}
.Production-block-container .standard.height,
.Production-block-container .compact.height,
.Production-block-container .plus.height,
.Production-block-container .luxus.height {
    height: 30px;
    text-align: center;
    padding: 5px 3px 5px 3px;
    /*padding-top: 5px;
    padding-bottom: 5px;*/
}

.Production-block-container .standard.custom-height,
.Production-block-container .compact.custom-height,
.Production-block-container .plus.custom-height,
.Production-block-container .luxus.custom-height
{
    height: 12px;
}

.Production-block-container .standard.custom-height-17,
.Production-block-container .compact.custom-height-17,
.Production-block-container .plus.custom-height-17,
.Production-block-container .luxus.custom-height-17
{
    height: 20px;
}

.pinContainer {
    position: relative;
    z-index: 6;
}
.pinImgView {
    position: absolute;
    width: 20px;
    /* top: 50%;
    left: 50%; */
    margin-left: -6.5px;
    margin-top: -23px;
    z-index: 10;
}
.dot-class {
    width: 2px;
    height: 2px;
    background-color: #000000;
    /*margin: auto;
    margin-top: 8px;*/
    border: 1px solid #000000;
    border-radius: 2px;
    position: absolute;
    left: 49%;
    top: 49%;
    z-index: 6;
}

.Production-block-container .Number-box {
    margin-left: 1px !important;
    width: 20px !important;
    height: 20px !important;
}

.custom-row-line .col-4 {
    z-index: 3;
}

.production-field-container {
    width: 61.88%;
}

.vertical-text-container {
    width: 38.12%;
	height: 80px;
    display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.production-field-container2 {
    width: 72%;
}
.vertical-text-container2 {
    width: 28%;
    height: 80px;
    display: flex;
	align-items: flex-end;
	justify-content: flex-end;
}

.speed-row-text {
    height: 20px;
}

.vertical-text {
    font-size: 5pt;
    transform: rotate(180.0deg);
    writing-mode: vertical-rl;
}
.vertical-text.text-bold{
    font-weight: bold;
}

.Production-droppable-field {
    background-color: #E5E6E7;
    height: 80px;
    text-align: center;
}

.custom-row-line .col-4 {
    margin: auto 0;
}

.Production-Number-box {
    background-color: #FFFFFF;
    border: 1px solid #000000;
    font-size: 8pt;
    color: #000000;
    font-weight: bold;
    width: 20px;
    height: 20px;
    text-align: center;
    margin: 0 auto
}

.Production-droppable-field {
    position: relative;
}

.field-content {
    position: absolute;
    top: 5px;
    left: 0px;
    right: 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    height: 100%;
}

.field-content-title,
.field-content-value {
    font-size: 8px;
    text-align: center;
}

.field-content-image-row {
    height: 40px;
    display: block;
}
.field-content-image-row .col {
    height: 100%;
    display: flex;
}

.field-content-image-row.machine img {
    width: 20px;
    margin: auto;
}
.field-content-image-row.product img {
    width: 28px;
    margin: auto;
}

.marginAuto {
	margin: auto;
}