.Purchase-block-container {
    width: 100%;
}

.Same-line {
    margin-right: 8px;
    margin-bottom: 1rem;
    float: left;
    padding: 10px;

}

.Purchase-bold-text {
	font-weight: bolder;
	text-transform: uppercase;
}

.Purchase-title-text-size {
    font-size: 9pt;
    margin-bottom: 30px;
}
.Purchase-subtitle-text-size {
    font-size: 7pt;
    font-weight: bold;
    /*margin-left: 9px;*/
    text-align: right;
}

.Purchase-text-size {
    font-size: 6pt;
}

.Text-italic {
    font-style: italic;
}

.Purchase-row {
    width: 170px;
    height: 49px;
    padding: 5px;
    text-align: left;
    color: #FFFFFF;
    font-size: 7pt;
    margin-bottom: 10px;
}

.Block-center {
    display: block;
    margin: auto 0;
}

.Standard-row {
    background-color: #23923C;
}
.Compact-row {
    background-color: #FBD901;
}
.Plus-row {
    background-color: #2AACD5;
}
.Luxus-row {
    background-color: #901027;
}

.Purchase-droppable-field {
    width: 100%;
    height: 100%;
    position: relative;
}

.Purchase-droppable-field::before {
    content: "";
    background-color: #FFFFFF;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}