.Research-block-container {
    width: 100%;
}

.Research-bold-text {
    font-weight: bolder;
}

.Research-title-text-size {
    font-size: 9pt;
    margin-bottom: 30px;
}
.Research-subtitle-text-size {
    font-size: 7pt;
    float: right;
    font-weight: bold;
    margin-left: 9px;

    
}

.Research-text-size {
    font-size: 6pt;
}

.Text-italic {
    font-style: italic;
}

.Research-row {
    width: 100%;
    margin-bottom: 10px;
    height: 49px;
    padding: 5px;
    text-align: left;
    color: #FFFFFF;
    font-size: 7pt;
}

.Block-center {
    display: block;
    margin: auto 0;
}

.Standard-row {
    background-color: #23923C;
}
.Compact-row {
    background-color: #FBD901;
}
.Plus-row {
    background-color: #2AACD5;
}
.Luxus-row {
    background-color: #901027;
}

.Search-droppable-field {
    width: 100%;
    height: 100%;
    position: relative;
}
.Search-droppable-field::before {
    content: "";
    background-color: #FFFFFF;
    opacity: 0.5;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
}

.Research-row .title {
	width: 21.19%;
	text-transform: uppercase;
}
.Research-row .number {
    width: 50%;
}
.Research-row .field {
    width: 80px;
}