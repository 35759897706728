.Left-block .text-column {
    padding-left: 5px
}

.Left-block .Arrow-class {
    right: -32px;
}

.Left-block-vertical-space {
    height: 60px;
}