.Sales-container{
    width: 40%;
    background-color: #bfc5d0;
    border: 1px solid #000;
    margin-top: 5px;
    text-align: left;
    padding: 0px 5px 5px 5px !important;
    z-index: 1;
}
.sales-point{    text-align: center;
    border: 1px solid black;
    background: white;
    color: red;
    padding: 5px 10px;
}
.sales-row{
    background: #8A9297;
    width: 70%;
    display: flex;
    margin: 30px auto !important;
}