.Bottom-block {
	background-color: #6076AB;
    text-align: left;
    padding: 5px!important;
    margin-top: 15px !important;
    color: #FFFFFF;
    width: 310px;
    position: relative;
    display: flex;
    justify-content: space-around;
    column-gap: 5px;
    margin: auto;
}

.Remove-col-left-padding {
	padding-left: 0px!important;
}

.Bottom-block .Remove-left-padding {
	padding-left: 0px!important;
	padding-right: 13px!important;
}

.Bottom-block .Remove-right-padding {
	padding-left: 13px!important;
	padding-right: 14px!important;
}

.Bottom-block .row .text-column {
	font-size: 6pt;
	word-break: break-word;
	margin-bottom: 4px;
}

.Bottom-block .Text-bold {
	font-weight: bold;
}
.Bottom-block > .row{
	width: 100%;
}

.Bottom-block-vertical-space {
	height: 60px;
}

.Remove-padding {
	padding: 0px !important;
}

.Bottom-block .Container-margin-bottom {
	margin-bottom: 8px;
}

.Bottom-block .Arrow-class {
	position: absolute;
	top: -32px;
	left: 30%;
	height: 30px;
}

.Remove-padding {
	padding: 0px!important;
}

.Droppable-container-margin {
	margin-right: 8px;
}

.Bottom-block .Custom-col {
	margin-right: 0.8em;
}

.Bottom-block .Bottom-droppable-field {
	background-color: #FFFFFF;
	height: 40px;
}

.Bottom-block .field-column {
	width: 49.64%;
}

.Bottom-block .text-column {
	width: 50.36%;
	padding-right: 5px
}