.Right-block {
    width: 80.95%;
}

.Right-block.bottom {
    width: 96.05%!important;
}

.Right-block .text-column {
    padding-right: 5px
}

.Right-block .Arrow-class {
    left: -32px;
}

.Right-block-vertical-space {
    height: 60px;
}

.Right-block .Droppable-container-margin {
    margin-right: 3px;
    margin-bottom: 3px;
}